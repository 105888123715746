import { Injectable } from '@angular/core';
import { Navigation } from 'app/core/navigation/navigation.types';
import { Observable, ReplaySubject } from 'rxjs';
import { adminNavigation, compactNavigation, menuNavigation } from './navigation-data';

@Injectable({
    providedIn: 'root'
})
export class NavigationService{
    private _navigation: ReplaySubject<Navigation> =
    new ReplaySubject<Navigation>(1);

  constructor() {
    this._navigation.next({
      admin: adminNavigation,
      default: menuNavigation,
      compact: compactNavigation,
    });
  }

  get navigation$(): Observable<Navigation> {
    return this._navigation.asObservable();
  }
}
