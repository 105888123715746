import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { IApiVersion, IConfiguracoes } from "./configuracoes.types";
import { environment } from "environments/environment";

@Injectable({ providedIn: "root" })
export class AppConfigService {
  private _config$ = new BehaviorSubject<IConfiguracoes>(null);
  private _apiVersion$ = new BehaviorSubject<IApiVersion>(null);

  constructor(private _http: HttpClient) {
    this.loadConfig();
    this.getApiVersion();
  }

  loadConfig() {
    this._http
      .get<IConfiguracoes>("assets/config/configuracoes.json")
      .subscribe((res: IConfiguracoes) => this._config$.next(res));
  }

  getApiVersion() {
    this._http.get<IApiVersion>(`${environment.api}/versao`)
      .subscribe((res: IApiVersion) => this._apiVersion$.next(res));
  }

  //
  //
  // Accessors
  get config$(): Observable<IConfiguracoes> {
    return this._config$.asObservable();
  }

  get config(): IConfiguracoes {
    return this._config$.value;

  }

  //
  //
  // Api Version
  get apiVersion$(): Observable<IApiVersion> {
    return this._apiVersion$.asObservable();
  }

  get apiVersion(): IApiVersion {
    return this._apiVersion$.value;
  }
}
