import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, throwError } from "rxjs";
import { AlertService } from "../alert/alert.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private requests: HttpRequest<unknown>[] = [];

  constructor(private _alertService: AlertService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.requests.push(request);
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = "";

        if (error.error instanceof ErrorEvent) {
          errorMessage = `Erro: ${error}`;
        } else {
          // server-side error
          switch (error.status) {
            case 404: // Not Found
              errorMessage = "Não foi encontrado a requisição";
              if (error.error.message) {
                errorMessage = error.error.message;
              }
              break;
            case 401: //
              errorMessage = "Usuário não autorizado";
              break;
            default:
              errorMessage = "Houve um erro desconhecido na Requisição. Tente novamente ou entre em contato com o suporte.";

              if (error?.error?.message) {
                if (error?.url.includes("senasp/pessoa-fisica/consulta-externa") && error?.error.fields.length) {
                  errorMessage = error.error.fields[0].message ;
                } else {
                  errorMessage = error.error.message;
                }
              } else if (error?.statusText) {
                errorMessage = error.statusText;
              }
          }
        }

        if (request.headers.get("skip")) {
          return throwError(() => errorMessage);
        }

        if(request.headers.get("observeError")) {
          return throwError(() => error);
        }

        this._alertService.error(errorMessage);
        return throwError(() => errorMessage);
      })
    );
  }
}
