/* tslint:disable:max-line-length */

import { FuseNavigationItem } from "@fuse/components/navigation";
import { AuthUtils } from "../auth/auth.utils";

export const menuNavigation: FuseNavigationItem[] = [
  {
    id: "home",
    title: "Início",
    type: "basic",
    icon: "heroicons_outline:home",
    link: "/home",
  },
  {
    id: "info",
    title: "Informações",
    type: "basic",
    icon: "heroicons_outline:home",
    link: "/home",
  },
  {
    id: "formCRE",
    title: "Formulário CRE",
    type: "basic",
    icon: "heroicons_outline:home",
    link: "/home",
  },
  {
    id: "regionais",
    title: "Regionais",
    type: "basic",
    icon: "heroicons_outline:home",
    link: "/home",
  },
];

export const compactNavigation: FuseNavigationItem[] = menuNavigation.map(
  (item: FuseNavigationItem) => {
    if (item?.type === "collapsable") {
      const newItem: FuseNavigationItem = {
        ...item,
        type: "aside",
      };
      return newItem;
    }

    return item;
  }
);

export const adminNavigation: FuseNavigationItem[] = [
  {
    id: "home-dashboard",
    title: "Home",
    type: "basic",
    icon: "heroicons_outline:home",
    link: "dashboard"
  },
  {
    id: "home-admin",
    title: "Precatórias",
    type: "aside",
    icon: "heroicons_outline:document-text",
    children: [
      {
        id: "precatorias-recebidas",
        title: "Precatórias Recebidas",
        type: "basic",
        icon: "heroicons_outline:document-text",
        link: "precatorias/recebidas/list",
      },
      {
        id: "precatorias-enviadas",
        title: "Precatórias Enviadas",
        type: "basic",
        icon: "heroicons_outline:document-text",
        link: "precatorias/enviadas/list",
      },
    ],
    hidden: () => desabilitaRota("visualizar_precatoria"),
  },
  {
    id: "usuario",
    title: "Usuários",
    type: "basic",
    icon: "feather:user",
    link: "usuario",
    hidden: () => desabilitaRota("visualizar_usuario"),
  },
  {
    id: "cadastros-gerais",
    title: "Cadastros Gerais",
    type: "aside",
    icon: "feather:layers",
    hidden: () => desabilitaRota("visualizar_perfil"),
    // link: "gestao/cadastros-gerais",
    children: [
      {
        id: "unidades",
        title: "Unidades",
        type: "basic",
        icon: "heroicons_outline:building-office-2",
        link: "cadastros-gerais/unidades",
      },
      {
        id: "cargos",
        title: "Cargos",
        type: "basic",
        icon: "heroicons_outline:identification",
        link: "cadastros-gerais/cargos",
      },
      {
        id: "perfil",
        title: "Perfil",
        type: "basic",
        icon: "heroicons_outline:key",
        link: "cadastros-gerais/perfis",
      },
    ]
    // hidden: () => desabilitaRota(""),
  }
];

function desabilitaRota(permissao: string): boolean {
  const token = localStorage.getItem("accessAdminToken");

  if (!token) {
    return true;
  }

  const { groups } = AuthUtils._decodeToken(token);

  if (Object(groups).find((p) => p === permissao)) return false;

  return true;
}
