import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthUtils } from "app/core/auth/auth.utils";
import { environment } from "environments/environment";
import { catchError, Observable, of, switchMap, throwError } from "rxjs";

@Injectable()
export class AuthService {
  private _authenticated: boolean = false;

  constructor(private _router: Router, private _httpClient: HttpClient) {}

  set accessToken(token: string) {
    localStorage.setItem("accessToken", token);
  }

  get accessToken(): string {
    return localStorage.getItem("accessToken") ?? "";
  }

  set email(token: string) {
    localStorage.setItem("email", token);
  }

  get email(): string {
    return localStorage.getItem("email") ?? "";
  }

  set protocolo(token: string) {
    localStorage.setItem("protocolo", token);
  }

  get protocolo(): string {
    return localStorage.getItem("protocolo") ?? "";
  }

  signIn(credentials: { protocolo: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError(() => ({
        message: "Usuário já logado.",
        authenticated: this._authenticated,
      }));
    }

    return this._httpClient
      .post<any>(
        `${environment.api}/v1/login?protocolo=${credentials.protocolo}`,
        null
      )
      .pipe(
        switchMap((response: any) => {
          // Store the access token in the local storage
          this.accessToken = response.accessToken;

          // Set the authenticated flag to true
          this._authenticated = true;

          // // Store the email
          this.email = response.email;

          // Store the código
          this.protocolo = credentials.protocolo;

          // Return a new observable with the response
          return of(response);
        })
      );
  }

  signInUsingToken(): Observable<any> {
    // Sign in using the token
    const credentials = {
      protocolo: this.protocolo,
    };
    return this.signIn(credentials).pipe(
      catchError(() =>
        // Return false
        of(false)
      ),
      switchMap((response: any) => {
        // Replace the access token with the new one if it's available on
        // the response object.
        //
        // This is an added optional step for better security. Once you sign
        // in using the token, you should generate a new one on the server
        // side and attach it to the response object. Then the following
        // piece of code can replace the token with the refreshed one.
        if (response.accessToken) {
          this.accessToken = response.accessToken;
        }

        // Set the authenticated flag to true
        this._authenticated = true;

        // Return true
        return of(true);
      })
    );
  }

  refleshToken(): Observable<any> {
    // Sign in using the token
    const credentials = {
      protocolo: this.protocolo,
    };
    this.signOut();
    return this.signIn(credentials);
  }

  signOut(): Observable<any> {
    this.clearStorage();

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  clearStorage() {
    // Remove the access token from the local storage
    localStorage.removeItem("accessToken");

    // Remove the access token from the local storage
    localStorage.removeItem("email");

    // Remove the access token from the local storage
    localStorage.removeItem("protocolo");
  }


  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    // If the access token exists and it didn't expire, sign in using it
    return this.signInUsingToken();
  }

  checkProtocolo(protocolo: string) {
    if (protocolo !== this.protocolo) {
      this.clearStorage();
      this._authenticated = false;
      this.protocolo = protocolo;
      this._router.navigate(["continuar-cadastro"]);
      // Return the observable
      return of(false);
    }

    // Return the observable
    return of(true);
  }
}
