import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthUtils } from "app/core/auth/auth.utils";
import { catchError, Observable, switchMap, throwError } from "rxjs";
import { AuthAdminService } from "./auth-admin.service";

@Injectable()
export class AuthAdminInterceptor implements HttpInterceptor {
  constructor(private _authService: AuthAdminService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.includes("admin/refresh-token")) {
      return next.handle(
        req.clone({
          headers: req.headers.set(
            "Authorization",
            "Bearer " + this._authService.accessAdminRefreshToken
          ),
        })
      );
    }

    // Verefica se e uma requisição do admin
    const adminFlag = req.headers.get("adminFlag");
    let lazyUpdate = req.headers["lazyUpdate"]
      ? req.headers["lazyUpdate"][0]
      : null;
    if (
      !req.url.includes("admin") &&
      lazyUpdate?.name != "admin" &&
      !adminFlag
    ) {
      return next.handle(req);
    }

    // Clone the request object
    let newReq = req.clone();

    if (
      this._authService.accessAdminToken &&
      !AuthUtils.isTokenExpired(this._authService.accessAdminToken)
    ) {
      newReq = req.clone({
        headers: req.headers.set(
          "Authorization",
          "Bearer " + this._authService.accessAdminToken
        ),
      });
    }

    if (
      this._authService.accessAdminToken &&
      AuthUtils.isTokenExpired(this._authService.accessAdminToken)
    ) {
      return this._authService.refleshToken().pipe(
        switchMap((res) => {
          return next.handle(
            req.clone({
              headers: req.headers.set(
                "Authorization",
                "Bearer " + res.accessToken
              ),
            })
          );
        }),

        catchError((error) => {
          // Catch "401 Unauthorized" responses
          // Sign out
          this._authService.signOut();

          // Reload the app
          location.reload();

          return throwError(() => new Error(error));
        })
      );
    }

    // Response
    return next.handle(newReq);
  }
}
