import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";

@Injectable({ providedIn: "root" })
export class AlterarSenhaAPIService {
  constructor(private http: HttpClient) {}

  alterarSenha(params: { novaSenha: string; confirmarSenha: string }) {
    const headers = new HttpHeaders().set("adminFlag", "true");

    return this.http.post(`${environment.api}/v1/admin/nova-senha`, params, {
      headers,
    });
  }
}
