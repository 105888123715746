import { NgModule } from "@angular/core";
import { LayoutComponent } from "app/layout/layout.component";
import { EmptyLayoutModule } from "app/layout/layouts/empty/empty.module";
import { SettingsModule } from "app/layout/common/settings/settings.module";
import { SharedModule } from "app/shared/shared.module";
import { ClassicLayoutModule } from "./layouts/classic/classic.module";
import { CompactLayoutModule } from "./layouts/compact/compact.module";

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    SharedModule,
    SettingsModule,
    EmptyLayoutModule,
    CompactLayoutModule,
    ClassicLayoutModule,
  ],
  exports: [LayoutComponent, EmptyLayoutModule, ClassicLayoutModule],
})
export class LayoutModule {}
