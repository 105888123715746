<fuse-alert
  [showIcon]="data.showIcon"
  [dismissed]="data.dismissed"
  [dismissible]="data.dismissible"
  [appearance]="data.appearance"
  [type]="data.type"
>
  {{ data.message }}

  <button
    mat-icon-button
    class="absolute top-2 right-2"
    (click)="handleClose()"
  >
    <mat-icon svgIcon="heroicons_solid:x" class="icon-size-4"></mat-icon>
  </button>
</fuse-alert>
