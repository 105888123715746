<div mat-dialog-title>
  <h1 class="text-4xl font-bold">Alterar Senha</h1>
</div>

<div mat-dialog-content>
  <div class="w-full max-w-5xl">
    <hr class="mb-4 mt-0 border-t-0 border-gray-200" />

    <form [formGroup]="form" class="flex flex-col gap-2 items-center">
      <!-- Nova Senha -->
      <mat-form-field class="w-full pb-2" appearance="outline">
        <mat-label class="justify-center">Nova senha</mat-label>
        <input
          matInput
          formControlName="novaSenha"
          type="password"
          #novaSenhaField
        />
        <button
          mat-icon-button
          type="button"
          (click)="
            novaSenhaField.type === 'password'
              ? (novaSenhaField.type = 'text')
              : (novaSenhaField.type = 'password')
          "
          matSuffix
        >
          <mat-icon
            class="icon-size-5"
            *ngIf="novaSenhaField.type === 'password'"
            [svgIcon]="'heroicons_solid:eye'"
          ></mat-icon>
          <mat-icon
            class="icon-size-5"
            *ngIf="novaSenhaField.type === 'text'"
            [svgIcon]="'heroicons_solid:eye-off'"
          ></mat-icon>
        </button>
        <mat-error *ngIf="form.controls.novaSenha.hasError('required')">
          Senha é obrigatória
        </mat-error>
        <mat-error *ngIf="form.controls.novaSenha.hasError('minlength')">
          Senha deve conter no mínimo 8 caracteres
        </mat-error>
        <mat-error *ngIf="form.controls.novaSenha.hasError('passwordStrength')">
          A senha deve conter pelo menos um caracter especial, uma letra maiúscula, uma minúscula e um número.
        </mat-error>
      </mat-form-field>

      <!-- Confirmar Senha -->
      <mat-form-field class="w-full mt-2" appearance="outline">
        <mat-label class="justify-center">Confirmar nova senha</mat-label>
        <input
          matInput
          formControlName="confirmarSenha"
          type="password"
          #confirmarSenhaField
        />
        <button
          mat-icon-button
          type="button"
          (click)="
            confirmarSenhaField.type === 'password'
              ? (confirmarSenhaField.type = 'text')
              : (confirmarSenhaField.type = 'password')
          "
          matSuffix
        >
          <mat-icon
            class="icon-size-5"
            *ngIf="confirmarSenhaField.type === 'password'"
            [svgIcon]="'heroicons_solid:eye'"
          ></mat-icon>
          <mat-icon
            class="icon-size-5"
            *ngIf="confirmarSenhaField.type === 'text'"
            [svgIcon]="'heroicons_solid:eye-off'"
          ></mat-icon>
        </button>
        <mat-error *ngIf="form.controls.confirmarSenha.hasError('required')">
          Confirmar a senha é obrigatório
        </mat-error>

        <mat-error *ngIf="form.value.novaSenha !== form.value.confirmarSenha">
          A confirmação não está igual a nova senha
        </mat-error>
      </mat-form-field>
    </form> 
  </div>
</div>

<div mat-dialog-actions class="mt-4 sm:mt-8 border-t border-gray-200">
  <div class="w-full flex flex-row gap-2 mt-2 justify-end">
    <button
      class="ml-2"
      mat-flat-button
      (click)="fecharDialog()"
    >
      <span class="ml-2 mr-1">Fechar</span>
    </button>
    
    <button
      mat-flat-button
      color="primary"
      (click)="handleSubmit()"
      [disabled]="form.invalid || isLoading"
    >
      Salvar
    </button>
  </div>
</div>