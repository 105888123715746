import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthUtils } from "app/core/auth/auth.utils";
import { environment } from "environments/environment";
import { tap } from "rxjs/operators";
import { InfoUser, UserToken } from "./rule";

@Injectable({
  providedIn: "root",
})
export class PermissionsService {
  constructor(private http: HttpClient) {}

  setUser() {
    return this.http
      .get<InfoUser>(`${environment.api}/v1/admin/login/usario-info`)
      .pipe(
        tap((user: InfoUser) => {
          this.user = user;
        })
      );
  }

  private geGroups(): string[] {
    return this.userToken.groups;
  }

  checkIfHaveRules(pRules: string[]): boolean {
    let exist = false;
    let roles = this.geGroups();
    if (!roles) {
      return;
    }

    pRules.find((pRule) => {
      roles.find((rule) => {
        if (pRule === rule) {
          exist = true;
        }
      });
    });
    return exist;
  }

  hasPermission(pRules: string[], id = 0): boolean {
    let permission = false;

    if (id) {
      permission = id === this.user.id;
      if (!permission && pRules["precatoriaGeral"]) {
        return permission;
      }
    }

    pRules.find((pRule) => {
      this.geGroups().find((rule) => {
        if (pRule === rule || rule === "precatoriaGeral") {
          permission = true;
        }
      });
    });

    return permission;
  }

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: InfoUser) {
    // Store the value
    sessionStorage.setItem("currentUser", JSON.stringify(value));
  }

  get user(): InfoUser {
    return JSON.parse(sessionStorage.getItem("currentUser"));
  }

  get userToken(): UserToken {
    return AuthUtils._decodeToken(localStorage.getItem("accessAdminToken"));
  }
}
