import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { environment } from "environments/environment";
import { AppModule } from "app/app.module";
import { EnvConfigStart } from "env-config-start";

const envConfigStart = new EnvConfigStart();

envConfigStart.getConfig().then((envs) => {

  Object.keys(envs).map((key) => {
    environment[key] = envs[key];
  });

  if (envs.envName === "prod") {
    enableProdMode();
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
