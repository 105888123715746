import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function confirmPasswordValidator(controlOriginal: AbstractControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const valueOriginal = controlOriginal.value;

    if (!valueOriginal) {
      return null;
    }

    const samePasswords = value === valueOriginal;

    return !samePasswords ? { confirmPassword: true } : null;
  }
}