<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [appearance]="'compact'"
  [mode]="isScreenSmall ? 'over' : 'side'" [name]="'mainNavigation'" [navigation]="navigation.admin"
  [opened]="!isScreenSmall">
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader class="relative">
    <!-- Logo -->
    <div class="flex items-center justify-center h-20 mt-3 mb-4">
      <img class="w-10" [src]="'assets/images/logo/' + configs?.nomeDoArquivoLogoInstituicao" alt="Logo image" />
    </div>

    
    <div
      class="flex absolute text-center flex-col bottom-3 left-4 mx-auto text-gray-400 text-[8px]"
    >
      <div>App: {{ commitHash }}</div>
      <div>Api: {{ apiVersion?.versao }}</div>
    </div>  
   
    
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 px-4 py-10 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
    <!-- Navigation toggle button -->
    <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
    </button>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <div class="flex flex-col items-end mt-2 mb-2">
        <div (click)="openDialogAlterarSenha()"
          class="text-lg md:text-xl font-semibold items-center flex gap-1 cursor-pointer justify-center leading-none"
          matTooltip="Clique para alterar a senha">
          <mat-icon svgIcon="heroicons_outline:user-circle" class="icon-size-5"></mat-icon>
          <div>
            {{ userToken.nome }}
          </div>
        </div>

        <div class="font-semibold text-secondary leading-none mb-1">
          {{ userToken.email }}
        </div>

        <a
          *ngIf="!configs?.disableAdminLogoutBtn"
          (click)="logOut()"
          class="font-bold flex items-center justify-center text-blue-600 cursor-pointer"
        >
          <mat-icon svgIcon="feather:log-out" class="text-blue-600 icon-size-5 mr-1"></mat-icon>
          <span>Sair</span>
        </a>
      </div>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <router-outlet *ngIf="true"></router-outlet>
  </div>
</div>