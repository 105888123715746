export const environment = {
  envName: '',
  production: false,
  api: "https://cpe-api.geia.vip",
  proxy: "https://integrador-proxy-api.geia.vip",
  version: "1.0.0 - 20220101",
  formularioPreenchido: false,
  appUrl: "https://cpe.geia.vip",
  copyrightText: "Development env",
  enableSSO: false,
  ssoAuthUrl: "",
  ssoBtnLabel: ""
};
