import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthUtils } from "app/core/auth/auth.utils";
import { environment } from "environments/environment";
import { mergeMap, Observable, of, switchMap, throwError } from "rxjs";
import { PermissionsService } from "./permission/permissions.service";
import { UserToken } from "./permission/rule";

@Injectable({
  providedIn: "root",
})
export class AuthAdminService {
  private _authenticated: boolean = false;

  constructor(
    private _httpClient: HttpClient,
    private _permissionsService: PermissionsService
  ) {
    this.consoleDebug('AuthAdminService.constructor() -> _authenticated: ' + this._authenticated);
  }

  set accessAdminToken(token: string) {
    this.consoleDebug("[SET METHOD] AuthAdminService.accessAdminToken(token) -> " + token);
    localStorage.setItem("accessAdminToken", token);
  }

  get accessAdminToken(): string {
    this.consoleDebug("[GET METHOD] AuthAdminService.accessAdminToken()");
    return localStorage.getItem("accessAdminToken") ?? "";
  }

  set accessAdminRefreshToken(token: string) {
    this.consoleDebug("[SET METHOD] AuthAdminService.accessAdminRefreshToken(token) -> " + token);
    localStorage.setItem("accessAdminRefreshToken", token);
  }

  get accessAdminRefreshToken(): string {
    this.consoleDebug("[GET METHOD] AuthAdminService.accessAdminRefreshToken()");
    return localStorage.getItem("accessAdminRefreshToken") ?? "";
  }

  get userToken(): UserToken {
    this.consoleDebug("[GET METHOD] AuthAdminService.userToken()");
    return this._permissionsService.userToken;
  }

  headers() {
    this.consoleDebug("AuthAdminService.headers()");

    let headers = new HttpHeaders();
    headers = headers.set(
      "Authorization",
      "Refresh " + this.accessAdminRefreshToken
    );
    return headers;
  }

  signIn(credentials: { login: string; password: string }): Observable<any> {
    this.consoleDebug(
      "AuthAdminService.signIn() - this._authenticated: " + this._authenticated
    );

    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError(() => "Usuário já logado.");
    }

    return this._httpClient
      .post(environment.api + "/v1/admin/login", credentials)
      .pipe(
        switchMap((response: any) => {
          // Store the access token in the local storage
          this.accessAdminToken = response.accessToken;
          this.accessAdminRefreshToken = response.refreshToken;

          // Set the authenticated flag to true
          this._authenticated = true;

          // Return a new observable with the response
          return of(response);
        }),
        // Setando os Perfis do Usuário
        mergeMap(() => this._permissionsService.setUser())
      );
  }

  ssoSignIn(token: string): Observable<any> {
    this.consoleDebug("AuthAdminService.ssoSignIn()");
    this.consoleDebug(
      "AuthAdminService.ssoSignIn() - this._authenticated: " +
        this._authenticated
    );

    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError(() => "Usuário já logado.");
    }

    return this._httpClient
      .post(
        environment.api + "/v1/admin/login/validar-token",
        {
          token,
        }
      )
      .pipe(
        switchMap((response: any) => {
          // Store the access token in the local storage
          this.accessAdminToken = response.accessToken;
          this.accessAdminRefreshToken = response.refreshToken;

          // Set the authenticated flag to true
          this._authenticated = true;

          // Return a new observable with the response
          return of(response);
        })
      )
  }

  refleshToken(): Observable<any> {
    this.consoleDebug("AuthAdminService.refleshToken()");
    return this._httpClient
      .post(`${environment.api}/v1/admin/refresh-token`, {})
      .pipe(
        switchMap((res: { accessToken: string; refreshToken: string }) => {
          if (res && res.accessToken) {
            this._authenticated = true;
            this.accessAdminToken = res.accessToken;
            this.accessAdminRefreshToken = res.refreshToken;
          }
          return of(res);
        })
      );
  }

  signOut(): Observable<any> {
    this.consoleDebug("AuthAdminService.signOut()");
    // Remove the access token from the local storage
    localStorage.clear();

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  check(): Observable<boolean> {
    this.consoleDebug("AuthAdminService.check()");

    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessAdminToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessAdminToken)) {
      return of(false);
    }

    return of(true);
  }

  consoleDebug(text: string) {
    if (!environment.production) {
      console.log(text);
    }
  }
}
