import { Route } from "@angular/router";
import { LayoutComponent } from "app/layout/layout.component";
import { AuthGuard } from "./core/auth/guards/auth.guard";

export const appRoutes: Route[] = [
  { path: "", pathMatch: "full", redirectTo: "inicio" },
  {
    path: "",
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "inicio",
        loadChildren: () =>
          import("app/modules/inicio/inicio.module").then(
            (m) => m.InicioModule
          ),
      },
      {
        path: "continuar-cadastro",
        loadChildren: () =>
          import("app/modules/cadastro/continuar/continuar.module").then(
            (m) => m.ContinuarModule
          ),
      },
      {
        path: "gerar-protocolo",
        loadChildren: () =>
          import(
            "app/modules/cadastro/criar-protocolo/criar-protocolo.module"
          ).then((m) => m.CriarProtocoloModule),
      },
    ],
  },
  {
    path: "admin",
    loadChildren: () =>
      import("app/modules/admin/admin.module").then((m) => m.AdminModule),
  },
  {
    path: "",
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "app/modules/admin/recuperar-senha/recuperar-senha.module"
          ).then((m) => m.RecuperarSenhaModule),
      },
    ],
  },
  {
    path: "",
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "cadastro-carta",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            "app/modules/cadastro/cadastro-carta/cadastro-carta.module"
          ).then((m) => m.CadastroCartaModule),
      },
      {
        path: "continuar-carta/:protocolo",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            "app/modules/cadastro/cadastro-carta/cadastro-carta.module"
          ).then((m) => m.CadastroCartaModule),
      },
      {
        path: "consulta-carta",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("app/modules/consulta/consulta.module").then(
            (m) => m.ConsultaModule
          ),
      },
      {
        path: "recibo-carta/:protocolo",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("app/modules/recibo/recibo.module").then(
            (m) => m.ReciboModule
          ),
      },
    ],
  },
];
